export const leaderboardQuery = /* GraphQL */ `
  query Leaderboard {
    leaderboard {
      entries {
        name
        position
        time
      }
    }
  }
`;
