import { Auth } from "aws-amplify";

const getIdToken = async () => ({
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
});

const { graphql_endpoint, region, user_pool_id, web_client_id } = (
  window as any
)["awsConfig"] as {
  graphql_endpoint: string;
  region: string;
  user_pool_id: string;
  web_client_id: string;
};

const awsExports = {
  aws_appsync_graphqlEndpoint:
    graphql_endpoint || process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: region || process.env.REACT_APP_REGION,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_project_region: region || process.env.REACT_APP_REGION,
  aws_cognito_region: region || process.env.REACT_APP_REGION,
  aws_user_pools_id: user_pool_id || process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id:
    web_client_id || process.env.REACT_APP_WEB_CLIENT_ID,
  graphql_headers: getIdToken,
};

export default awsExports;
