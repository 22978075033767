import "./App.css";
import "@aws-amplify/ui-react/styles.css";

import { API, graphqlOperation } from "aws-amplify";
import { GraphQLSubscription } from "@aws-amplify/api";
import React, { useCallback, useEffect, useState } from "react";
import { leaderboardQuery } from "./graphql/queries";
import { onPublishLeaderboardSubscription } from "./graphql/subscriptions";

import { Leaderboard } from "./graphql/types";

import { withAuthenticator } from "@aws-amplify/ui-react";
// import { useIntervalAsync } from "./utils";

import logo from "./logo.png";

function App() {
  const [leaderboard, setLeaderboard] = useState<Leaderboard>();

  const fetchScoreboard = useCallback(async () => {
    try {
      const leaderboardData = await (API.graphql(
        graphqlOperation(leaderboardQuery)
      ) as Promise<{
        data: {
          leaderboard: Leaderboard
        };
      }>);
      const leaderboard = leaderboardData.data.leaderboard;
      setLeaderboard(leaderboard);
    } catch (err) {
      console.log("error fetching scoreboard");
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchScoreboard();

    const subscription = API.graphql<
      GraphQLSubscription<{
        onPublishLeaderboard: {
          entries: [{ position: number; name: string; time: string }];
        };
      }>
    >(graphqlOperation(onPublishLeaderboardSubscription)).subscribe({
      next: ({ value }) => setLeaderboard(value.data?.onPublishLeaderboard),
      error: (error) => console.warn(error),
    });

    return () => subscription.unsubscribe();
  }, [fetchScoreboard]);

  // useIntervalAsync(fetchScoreboard, 5000);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>RaceSim Leaderboard</p>
      </header>
      <div className="App-content">
        {leaderboard &&
          leaderboard.entries.map((entry) => (
            <div key={entry.position} className="Leaderboard-entry">
              <div>{entry.position}</div>
              <div>{entry.name}</div>
              <div>{entry.time}</div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
