export const onPublishLeaderboardSubscription = /* GraphQL */ `
  subscription OnPublishLeaderboard {
    onPublishLeaderboard {
      entries {
        name
        position
        time
      }
    }
  }
`;
